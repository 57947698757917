@import '../../../../styles/color.scss';
@import '../../../../styles/devices.mixins';
@import '../../../../styles/main.scss';
.wrapper {
  display: flex;
  flex-direction: column;
  padding: 24px;
  padding-left: 23px;
  padding-right: 23px;
  border: 1px solid #9ca3b0;
  justify-content: space-between;
  width: 25%;
  height: 558px;

  @include tablet-width {
    width: 100%;
  }

  @include mobile-width {
    width: 100%;
  }
  &__optimal {
    background-color: #f6f9ff;
  }
}
.descLabel {
  font-family: Metropolis;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
}
.custom {
  font-family: Metropolis;
  font-size: 24px;
  font-weight: 600;
  line-height: 42px;
  text-align: left;
  color: $black;
}
.planTitle {
  display: flex;
  width: 100%;
  line-height: 27px;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
}
.planInfo {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.contentHolder {
  display: flex;
  flex-direction: column;
  gap: 108px;
}

.buttonContainer {
  padding: 0px 24px 0px 24px;
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
  min-width: 242px;
  margin-top: 48px;
}

.priceContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.price {
  font-family: Metropolis;
  font-size: 45px;
  font-weight: 700;
  line-height: 45px;
  text-align: left;
  color: $black;
}

.priceHolder {
  display: flex;
  align-items: flex-end;
  font-family: Metropolis;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #727272;

  text-wrap: nowrap;
  @include mobile-width {
    text-wrap: wrap;
  }
}

.button {
  @include button-desktop;
  @include center;
  position: relative;
  width: 242px;
  height: 64px;
  border: 2px solid $black;
  background-color: $white;
  overflow-x: clip;
  isolation: isolate;
  font-size: 18px;
  line-height: 30px;

  &__optimal {
    background-color: #f6f9ff;
  }
  &-toggled {
    @include button-desktop;
    @include center;
    position: relative;
    width: 162px;
    height: 48px;
    border: 2px solid $black;
    background-color: $white;
    overflow-x: clip;
    isolation: isolate;
    @include tablet-width {
      // @include button-tablet;
      font-size: 18px;
      inline-size: 18px;
      color: $white;
      background: $black;
      width: 100%;
      height: 64px;
    }

    @include mobile-width {
      @include button-mobile;
      width: 100%;
      height: 48px;
    }
  }
  @include small-desktop {
    @include button-desktop-small;
    font-size: 14px;
    inline-size: 18px;
    line-height: 30px;
    width: 100%;
    height: 48px;
  }

  @include tablet-width {
    // @include button-tablet;
    font-size: 18px;
    inline-size: 18px;
    color: $white;
    background: $black;
    width: 100%;
    height: 64px;
  }

  @include mobile-width {
    @include button-mobile;
    width: 100%;
    height: 64px;
  }

  &:before {
    position: absolute;
    content: '';
    top: 0;
    right: 10%;
    width: 20px;
    height: 2px;
    background-color: inherit;
    transform: translateY(-100%);
    opacity: 1;
    transition: opacity linear 0.5s;

    @include tablet-width {
      display: none;
    }
  }

  &-overlay {
    @include button-desktop;
    line-height: inherit;
    @include center;
    display: inline-flex;
    position: absolute;
    top: 0;
    left: 0px;
    width: 0;
    height: 100%;
    background-color: $black;
    color: $white;
    text-transform: uppercase;

    @include tablet-width {
      display: none;
    }
  }

  &:hover {
    &:before {
      opacity: 0;
    }
    background-color: $black;
    color: $white;
  }
}

.subsCard {
  //width: 379px;
  max-height: 560px;
  border-radius: 20px;
  border: 1px solid #9ca3b0;
  padding: 24px;
  gap: 32px;
  display: grid;
  grid-template-rows: auto 1fr auto;
  box-sizing: border-box;
  flex: 1;
  min-width: 378px;
  max-width: 378px;
  @include mobile-width {
    flex: 1 1 100%;
    min-width: 50%;
    max-width: 100%;
  }
  @include small-desktop {
    flex: 1 1 calc(50% - 12px);
  }
}

.recomendedCard {
  border: 1px solid #1f1f1f;
  background-color: #f6f9ff;
}

.headerHolder {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
}

.activeTab {
  background: radial-gradient(113.26% 175.39% at 14.67% -17.64%, #000000 0%, #737374 100%);
  padding: 8px 14px 8px 14px;
  color: #ffffff;
  max-height: 35px;
  border-radius: 20px;
  font-size: 16px;
}

.header {
  width: 100%;
  display: flex;
  > div:first-child {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
  }
}

.priceWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
}

.name {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: #1f1f1f;
  text-transform: uppercase;
}

.price {
  height: 27px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.priceText {
  font-size: 32px;
  font-weight: 700;
  line-height: 39.07px;
  text-align: left;
  color: #1f1f1f;
}

.monthText {
  font-size: 20px !important;
  font-weight: normal;
  color: #727272 !important;
  align-self: center;
}

.customText {
  font-size: 21px;
  font-weight: 700;
  line-height: 25.64px;
  letter-spacing: 0.03em;
  text-align: left;
}

.table {
  display: flex;
  flex-direction: column;
  hr {
    margin: 8px 0 8px 0;
    color: #eaeef4;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
}

.tableHeader {
  @extend .row;
  font-size: 12px;
  line-height: 14.32px;
  text-align: left;
  color: #b3b3b3;
  text-transform: uppercase;
}
.tableBody {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.rowWrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.infIcon {
  width: 24px;
  height: 24px;
  align-self: center;
}
.tableRow {
  @extend .row;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  border-bottom: 1px solid #eaeef4;
  padding-top: 12px;
  padding-bottom: 12px;
  span {
    font-size: 16px;
    line-height: 24px;
  }
  &:last-child {
    padding-bottom: 0;
    border: 0;
  }
}

.circle {
  width: auto;
  height: 25px;
  padding: 4px 14px 4px 14px;
  gap: 10px;
  border-radius: 50px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.09px;
  text-align: center;
}

.greenCircle {
  @extend .circle;
  background-color: #ddffc8cc;
  color: #474f41;
}

.blackCircle {
  @extend .circle;
  background-color: #1f1f1f;
  color: #ffffff;
  display: flex;
}

.footer {
  display: flex;
  padding: 32px 0px 32px 0px;
  height: 96px;
  justify-content: center;
  align-items: center;
  flex: 1;
}
