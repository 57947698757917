@import '../../styles/main';
@import '../../styles/color';

.footer-item {
  cursor: pointer;
  height: fit-content;
  overflow-y: hidden;
  width: 100%;
  align-items: flex-end;
  margin-bottom: 16px;
  @include mobile-width {
    text-transform: capitalize;
  }

  &-rights {
    width: fit-content;
    margin-right: 24px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: fit-content;
    @include h5-mobile;
    font-family: Metropolis;
    font-weight: 500;
    &:last-child {
      margin-right: 0;
    }

    @include mobile-width {
      @include h6-mobile;
      line-height: 21px;
      text-wrap: nowrap;
      text-transform: capitalize;
    }
  }

  // &:not(:last-of-type) {
  //   margin-right: 20px;
  // }

  margin-right: 20px;

  @include mobile-width {
    margin-bottom: 8px;
  }
  .footer-slider {
    -webkit-line-clamp: 1;
    transform: translateY(0%);
    transition: transform 500ms;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 30px; /* 150% */
    height: 25px;
    & > p {
      text-overflow: ellipsis;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      line-height: 30px; /* 150% */
      padding: 5px 0;
    }
  }

  .footer-notAnimatedItem {
    line-height: 24px;
    text-transform: uppercase;
    padding: 5px 0;
    :hover {
      color: $grey;
    }
  }

  &:hover .footer-slider {
    transform: translateY(-170%);
    @include small-desktop {
      transform: translateY(-170%);
    }
    @include mobile-width {
      transform: translateY(0%);
      color: $grey;
      padding: 0px 0;
    }
    @include tablet-width {
      transform: translateY(0%);
      color: $grey;
      padding: 0px 0;
    }
  }
}
