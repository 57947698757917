@import '../../styles/main';
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 108px;
  margin-top: 72px;
  @include mobile-width {
    margin-top: 40px;
    margin-bottom: 64px;
  }
  &.disabled li:not(:first-child):not(:last-child) > a {
    display: none;
  }
}

.pagination li {
  list-style-type: none;
  cursor: pointer;
}

.pagination li.active {
  font-weight: bold;
}

.pagination li.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.pagination li > a {
  text-decoration: none;
  color: #333;
  padding: 4px 12px;
  @include h5-desktop-small;
  font-family: Metropolis;
  font-weight: 400;
  @include mobile-width {
    font-size: 10px;
  }
}

.pagination li.active > a {
  background-color: var(--black, #22242c);
  color: #fff;
}

.pagination li:first-child > a,
.pagination li:last-child > a {
  margin: 0;
  padding: 4px 0px;
}

.pagination li:first-child {
  margin-right: auto;
  color: var(--black, #22242c);
  @include h6-desktop-small;
  font-family: Metropolis;
  font-weight: 500;
  text-transform: uppercase;
  @include mobile-width {
    font-size: 12px;
  }
}

.pagination li:last-child {
  @include h6-desktop-small;
  color: var(--black, #22242c);
  font-family: Metropolis;
  font-weight: 500;
  text-transform: uppercase;
  margin-left: auto;
  @include mobile-width {
    font-size: 12px;
  }
}

.pagination li:not(:first-child):not(:last-child) {
  display: flex;
  justify-content: center;
}

.blog {
  &.page-flex-wrapper {
    min-height: calc(100vh - 122px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 200px;
    flex-direction: column;

    @include tablet-width {
      padding-top: 200px;
    }

    @include mobile-width {
      padding-top: 100px;
    }
  }
}

.blogWrapper {
  &-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    @include small-desktop {
      gap: 20px;
    }
    @include tablet-width {
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 0px;
    }
    @include mobile-width {
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 0px;
    }
  }
  &-title {
    margin-bottom: 72px;
    @include tablet-width {
      margin-bottom: 72px;
    }

    @include mobile-width {
      margin-bottom: 48px;
    }
    &-disabled {
      display: none;
    }
  }
  &-holder {
    max-width: 936px;
    width: 100%;
  }
  &-success {
    max-width: 100%;
    width: 100%;
  }
  &-inner {
    width: 100%;
  }
  &-flex-container {
    max-width: 936px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px;
    @include small-desktop {
      max-width: 700px;
      flex-direction: column;
    }
    @include tablet-width {
      display: flex;
      justify-content: stretch;
      flex-direction: column;
      max-width: 100%;
    }

    @include mobile-width {
      display: flex;
      justify-content: stretch;
      flex-direction: column;
      max-width: 100%;
      gap: 40px;
    }
    &-item {
      flex: 1;
    }
  }
  &-side-info-bar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 360px;
    gap: 50px;
    @include small-desktop {
      max-width: 260px;
    }

    @include tablet-width {
      max-width: 100%;
    }
    @include mobile-width {
      max-width: 100%;
    }

    &-items-holder {
      overflow-x: auto;
      display: flex;
      flex-direction: column;
      @include regular-desktop {
        flex: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
      }
      @include small-desktop {
        flex: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
      }
      @include tablet-width {
        display: flex;
        align-items: flex-start;
        flex-direction: initial;
        gap: 96px;
        align-self: stretch;
      }
      @include mobile-width {
        display: flex;
        align-items: flex-start;
        flex-direction: initial;
        gap: 24px;
        align-self: stretch;
        width: fit-content;
        overflow-x: auto;
      }
    }

    &-label {
      font-family: Metropolis;
      font-weight: 500;
      text-transform: uppercase;
      @include h4-desktop;
      font-style: normal;
      line-height: 36px;
      @include small-desktop {
        @include h3-desktop-small;
      }
      @include tablet-width {
        @include h5-tablet;
      }
      @include mobile-width {
        @include h3-mobile;
      }
    }
    &-button {
      @include h5-desktop-small;
      background-color: white;
      border: none;
      text-align: center;
      display: inline-block;
      cursor: pointer;

      color: var(--grey-01, #979caf);
      text-align: justify;
      /* Desk S/H5 - Text */
      font-family: Metropolis;
      font-weight: 400;

      @include tablet-width {
        display: none;
      }

      @include mobile-width {
        display: none;
      }
    }
    &-recrent-posts {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;
    }
    &-categories {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      // @include mobile-width {
      //   order: 2;
      // }
      &-link {
        @include h6-desktop;
        color: var(--black, #22242c);
        text-transform: initial;
        text-align: -webkit-match-parent;
        font-family: Metropolis;
        font-weight: 400;
        line-height: 24px;
        @include small-desktop {
          @include h6-desktop-small;
        }
        @include tablet-width {
          @include h6-tablet;
        }
        @include mobile-width {
          @include h6-mobile;
        }
        &:hover {
          color: gray;
          cursor: pointer;
        }
      }
    }
    &-archives {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      // @include mobile-width {
      //   order: 2;
      // }
      &-link {
        @include h6-desktop;
        color: var(--black, #22242c);
        text-transform: initial;
        text-align: justify;
        font-family: Metropolis;
        font-weight: 400;
        &:hover {
          color: gray;
          cursor: pointer;
        }
        @include small-desktop {
          @include h6-desktop-small;
        }
        @include tablet-width {
          @include h6-tablet;
        }
        @include mobile-width {
          @include h6-mobile;
        }
      }
    }
    &-discover {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      &-link {
        @include h6-desktop;
        color: var(--black, #22242c);
        text-transform: initial;
        text-align: justify;
        font-family: Metropolis;
        font-weight: 400;

        @include small-desktop {
          @include h6-desktop-small;
        }
        @include tablet-width {
          @include h6-tablet;
        }
        @include mobile-width {
          @include h6-mobile;
        }
      }
      &-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;

        &-item {
          display: flex;
          width: fit-content;
        }
      }
    }
  }
}

.scrollable-wrapper {
  width: 100%;
  overflow-x: auto;
  @include mobile-width {
    height: fit-content;
  }
}

.hireSection {
  &-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 88px;
    margin-top: 88px;
    &-disabled {
      display: none;
    }
    @include tablet-width {
      margin-bottom: 136;
      margin-top: 136px;
    }
    @include mobile-width {
      margin-bottom: 136px;
      margin-top: 136px;
    }
  }

  &-fields-holder {
    max-width: 936px;
    width: 100%;
    @include tablet-width {
      max-width: 696px;
    }
    @include mobile-width {
      max-width: 328px;
    }
  }

  &-title {
    max-width: 576px;
    margin-bottom: 100px;

    @include just-small-desktop {
      margin-bottom: 78px;
    }

    @include tablet-width {
      max-width: none;
    }
  }
}
