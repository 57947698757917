@import '../../styles/main';
.path {
  &-address {
    path {
      stroke-width: 1.5px;
    }
  }
}

.iconWrapper {
  display: inline-flex;
  &.textIncluded {
    width: 24px;
    height: 24px;
    @include tablet-width {
      width: 32px;
      height: 32px;
    }

    @include mobile-width {
      width: 24px;
      height: 24px;
    }
  }
  &.noText {
    @include tablet-width {
      width: 48px;
      height: 48px;
    }

    @include mobile-width {
      width: 32px;
      height: 32px;
    }
  }
  &.footer {
    width: 32px;
    height: 32px;

    &-hasText {
      width: 32px;
      height: 32px;
      @include tablet-width {
        display: none;
      }

      @include mobile-width {
        display: none;
      }
    }
  }
}
.icon {
  stroke-width: 1.5px;
  width: 100%;
  height: 100%;
}

.iconHeader {
  stroke-width: 1.5px;
  width: 100%;
  height: 100%;
  max-height: 24px;
  max-width: 24px;
}

.liWrapper {
  display: inline-flex;
  align-items: center;
  color: #22242c;
  font-family: Metropolis;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  gap: 8px;

  @include tablet-width {
    font-size: 24px;
    padding-right: 0;
  }

  @include mobile-width {
    font-size: 18px;
    padding-right: 0;
  }

  &-footer {
    color: var(--black, #22242c);
    font-family: Metropolis;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    display: flex;
    align-items: center;
    margin-right: 24px;
    gap: 8px;
    @include tablet-width {
      padding-right: 0;
      margin-right: 36px;
      font-size: 16px;
    }

    @include mobile-width {
      margin-right: 16px;
      font-size: 14px;
      padding-right: 0;
    }
  }

  &.liWrapperNoText {
    display: inline-flex;
    align-items: center;

    @include tablet-width {
      display: inline-flex;
    }

    @include mobile-width {
      display: inline-flex;
    }
  }
}

.defaultWrapper {
  width: 100%;
  height: 100%;
  padding: 0px 9px 0px 0px;
}

.defaultIconWrapper {
  width: 100%;
  height: 100%;
}

.contactsTemplate {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
}
.contactsTemplateText {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 16px;
  font-family: Metropolis;
  @include h4-desktop;
  font-weight: 500;
  line-height: 36px;

  @include small-desktop {
    @include h4-desktop-small;
  }

  @include tablet-width {
    @include h4-tablet;
  }

  @include mobile-width {
    @include h4-mobile;
  }
}

.liWrapper:last-child {
  padding-right: 0;
}
