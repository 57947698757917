@import '../../../../styles/devices.mixins';
@import '../../../../styles/text.mixins';
@import '../../../../styles/main';

.itemContainer {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  min-height: 509px;
  &__lastSection {
    min-height: 350px;
  }

  @include small-desktop {
    gap: 32px;
  }
  @include tablet-width {
    gap: 100px;
    flex-direction: column;
  }
}

.itemContainerWrapper {
  width: 100%;
  height: calc(100vh - 400px);
  display: flex;
  justify-content: center;
  align-items: baseline;
  @include tablet-width {
    height: calc(100vh - 200px);
  }
  @include smallTablet-width {
    height: calc(100vh - 200px);
  }

  @include mobile-width {
    height: calc(100vh - 220px);
  }
}

.leftContent {
  flex: 1;
  max-width: 601px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;

  @include small-desktop {
    max-width: 100%;
  }
  @include tablet-width {
    gap: 32px;
    flex-direction: column;
  }

  @include mobile-width {
    gap: 0px;
  }
}

.rightContent {
  max-width: 729px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  @include small-desktop {
    max-width: 100%;
  }
  @include tablet-width {
    gap: 32px;
    flex-direction: column;
  }
}

.title {
  font-size: 32px;
  line-height: 48px;
  font-weight: 500;
  text-transform: uppercase;

  @include small-desktop {
    @include h3-desktop-small;
    margin: 8px 0 16px;
  }

  @include tablet-width {
    @include h3-tablet;
    margin: 16px 0 24px;
  }

  @include mobile-width {
    @include h3-mobile;
  }
}
.paragraph {
  font-size: 18px;
  line-height: 27px;
  text-align: justify;
  font-weight: 400;
}

.buttonHolder {
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
}

.button {
  @include button-desktop;
  @include center;
  position: relative;
  width: 380px;
  height: 68px;
  border: 2px solid $black;
  background-color: $white;
  overflow-x: clip;
  isolation: isolate;
  font-size: 18px;
  line-height: 30px;
  &-toggled {
    @include button-desktop;
    @include center;
    position: relative;
    width: 162px;
    height: 48px;
    border: 2px solid $black;
    background-color: $white;
    overflow-x: clip;
    isolation: isolate;
    @include tablet-width {
      // @include button-tablet;
      font-size: 18px;
      inline-size: 18px;
      color: $white;
      background: $black;
      width: 380px;
      height: 64px;
    }

    @include mobile-width {
      @include button-mobile;
      width: 162px;
      height: 48px;
    }
  }
  @include small-desktop {
    @include button-desktop-small;
    font-size: 14px;
    inline-size: 18px;
    line-height: 30px;
    width: 380px;
    height: 64px;
  }

  @include tablet-width {
    // @include button-tablet;
    font-size: 18px;
    inline-size: 18px;
    color: $white;
    background: $black;
    width: 380px;
    height: 64px;
  }

  @include mobile-width {
    @include button-mobile;
    width: 100%;
    height: 64px;
  }

  &:before {
    position: absolute;
    content: '';
    top: 0;
    right: 10%;
    width: 20px;
    height: 2px;
    background-color: inherit;
    transform: translateY(-100%);
    opacity: 1;
    transition: opacity linear 0.5s;

    @include tablet-width {
      display: none;
    }
  }

  &-overlay {
    @include button-desktop;
    line-height: inherit;
    @include center;
    display: inline-flex;
    position: absolute;
    top: 0;
    left: 0px;
    width: 0;
    height: 100%;
    background-color: $black;
    color: $white;
    text-transform: uppercase;

    @include tablet-width {
      display: none;
    }
  }

  &:hover {
    &:before {
      opacity: 0;
    }
    background-color: $black;
    color: $white;
  }
}
