@import '../../styles/main';

.divider {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  height: 1px;
  background-color: #c5c8d3;
  &.top {
    margin-bottom: 8px;
  }

  @include mobile-width {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.footer {
  &-inner {
    @include flex;
    @include h6-desktop;
    max-width: 1570px;
    margin: 0 auto;
    flex-wrap: wrap;
    font-family: Metropolis;
    gap: 24px;
    @include small-desktop {
      @include h5-mobile;
      max-width: 1132px;
      padding: 0px 0px;
    }

    @include tablet-width {
      @include h5-mobile;
      padding: 0px 0px;
    }

    @include mobile-width {
      padding: 0px 0px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }

    @media screen and (max-width: 580px) {
      display: flex;
      flex-direction: column;
    }
  }

  &-mobileFooter {
    display: none;
    width: 100%;
    margin-top: 12px;
    @include tablet-width {
      display: none;
    }

    @include mobile-width {
      display: flex;
      flex-direction: column;
      align-items: center;

      padding-bottom: 72px;
    }
  }

  &-list {
    display: flex;

    @media screen and (max-width: 580px) {
      margin-bottom: 20px;
    }
  }

  &-dividerHolder {
    width: 100%;
    @include mobile-width {
      display: none;
    }
  }

  &-rightsHolder {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    padding: 24px 0px 24px 0px;
  }

  &-list-copyright {
    display: flex;
    color: var(--black, #22242c);
    font-family: Metropolis;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: initial;
    order: 1;

    @include tablet-width {
      font-weight: 400;
      order: 0;
    }

    @include mobile-width {
      order: 0;
      font-weight: 400;
      font-size: 14px;
    }
  }
}
.footerWrapper {
  display: inline-flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  @include mobile-width {
    flex-direction: column;
    gap: 24px;
  }
  &-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    @include mobile-width {
      align-items: flex-start;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    @include mobile-width {
      gap: 8px;
    }
  }
  &-logo {
    margin-bottom: 30px;
    justify-content: center;
    display: flex;
  }
  &-description {
    display: flex;
    @include h5-mobile;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-transform: initial;
    margin-bottom: 47px;

    @include mobile-width {
      @include h6-mobile;
      text-align: justify;
      font-family: Metropolis;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      margin-bottom: 0;
    }

    &-details {
      display: flex;
      flex-direction: column;
      text-transform: initial;
      gap: 16px;
      @include mobile-width {
        gap: 8px;
      }
      &-social {
        margin-top: 36px;
        margin-bottom: 36px;
        display: inline-flex;
        @include mobile-width {
          margin-top: 24px;
          margin-bottom: 24px;
        }
      }
      &-hireButtonWrapper {
        width: 162px;
        height: 48px;
      }
      &-info {
        @include mobile-width {
          display: none;
        }
      }
      &-mobileInfo {
        display: none;
        @include small-desktop {
          display: none;
        }

        @include tablet-width {
          display: none;
        }
        @include mobile-width {
          flex-direction: column;
          display: flex;
        }
      }
    }
  }
}

.firstItem {
  align-items: self-start;
}
.firstColumn {
  width: 516px;
  @include tablet-width {
    width: 348px;
  }

  @include mobile-width {
    width: 100%;
  }
}
.secondColumn {
  width: 445px;
  @include small-desktop {
    width: 100%;
  }
  @include tablet-width {
    max-width: 300px;
    width: 100%;
  }
  @include mobile-width {
    max-width: 100%;
    width: 100%;
  }
}
.thirdColumn {
  width: 150px;
  @include tablet-width {
    width: 100%;
  }
  @include mobile-width {
    width: 100%;
  }
}
.labelHolder {
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 25px;
  &-label {
    @include h5-boldDesktop;
    @include mobile-width {
      @include h6-mobile;
    }
  }
  @include mobile-width {
    height: fit-content;
    margin-bottom: 11px;
    margin-top: 0;
  }
}

.copyright {
  @include h5-mobile;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
// .label {
//   @include h5-boldDesktop;
//   // margin-bottom: 30px;
//   // margin-top: 10px;
// }
