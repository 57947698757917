@import '../../../styles/devices.mixins';
@import '../../../styles/text.mixins';
@import '../../../styles/color';

.buttonContentWraper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: fit-content;
}
.contentHolder {
  display: flex;
  flex-direction: column;
  gap: 44px;
}

.label {
  font-size: 16px;
  color: $black;
  font-weight: 400;
}
.image {
  cursor: pointer;
  width: 728px;
  height: 409px;
  box-shadow: 0px 1.76px 2.4px 0px #00000009, 0px 4.23px 5.77px 0px #0000000d,
    0px 7.96px 10.87px 0px #00000010, 0px 14.19px 19.39px 0px #00000013,
    0px 26.54px 36.28px 0px #00000016, 0px 63.54px 86.83px 0px #0000001f;
  border-radius: 10px;

  @include small-desktop {
    width: 640px;
    height: 360px;
  }

  @include tablet-width {
    width: 100%;
    height: 100%;
  }
}
