@import '../../../styles/devices.mixins';

.flexSection {
  display: flex;
  flex-direction: column;
  height: fit-content;
  gap: 44px;
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 304px;

  @include mobile-width {
    gap: 150px;
  }
}
.customParagraph {
  max-width: 457px;
  font-size: 18px;
  line-height: 27px;
  text-align: justify;
  font-weight: 400;
}

.titleWrapper {
  max-width: 1024px;
  align-self: baseline;
}
