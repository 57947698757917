@import '../../../../styles/devices.mixins';

.imagesHolder {
  position: relative;
  display: flex;
  align-self: flex-end;
  width: 100%;
  &__single {
    justify-content: flex-end;
  }

  @include tablet-width {
    flex-direction: column;
    gap: 24px;
  }
}

.frontImage {
  max-width: 671px;
  max-height: 377px;
  border-radius: 10px;
  box-shadow: 0px 4.23px 5.77px 0px #0000000d, 0px 7.96px 10.87px 0px #00000010,
    0px 14.19px 19.39px 0px #00000013, 0px 26.54px 36.28px 0px #00000016,
    0px 63.54px 86.83px 0px #0000001f;

  @include small-desktop {
    width: 100%;
    height: 100%;
  }
  @include tablet-width {
    width: 80%;
    height: 80%;
    align-self: center;
  }
}

.backImage {
  max-width: 671px;
  max-height: 377px;
  z-index: -1;
  bottom: 32%;
  right: 0%;
  position: absolute;
  border-radius: 10px;
  box-shadow: 0px 4.23px 5.77px 0px #0000000d, 0px 7.96px 10.87px 0px #00000010,
    0px 14.19px 19.39px 0px #00000013, 0px 26.54px 36.28px 0px #00000016,
    0px 63.54px 86.83px 0px #0000001f;

  @include small-desktop {
    width: 100%;
    height: 100%;
    left: 10%;
  }
  @include tablet-width {
    width: 80%;
    height: 80%;
    left: 15%;
  }
}

.singleImage {
  justify-self: flex-end;
  border-radius: 10px;
  max-width: 671px;
  max-height: 377px;
  box-shadow: 0px 4.23px 5.77px 0px #0000000d, 0px 7.96px 10.87px 0px #00000010,
    0px 14.19px 19.39px 0px #00000013, 0px 26.54px 36.28px 0px #00000016,
    0px 63.54px 86.83px 0px #0000001f;

  @include small-desktop {
    width: 100%;
    height: 100%;
  }
  @include tablet-width {
    width: 80%;
    height: 80%;
    align-self: center;
  }
}
