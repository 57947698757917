@import '../../styles/main';
.ourLocationsTitle {
  @include h2-desktop;
  font-family: Metropolis;
  line-height: 48px; /* 85.714% */
  text-transform: uppercase;
  justify-content: flex-start;
  @include small-desktop {
    @include h2-desktop-small;
  }
  @include tablet-width {
    @include h2-tablet;
  }

  @include mobile-width {
    @include h2-mobile;
  }
}
.contactsForm {
  &-inner {
    display: flex;
    gap: 30px;
    margin-top: 304px;
    margin-bottom: 193px;
    width: 100%;
    @include tablet-width {
      margin-top: 184px;
      margin-bottom: 78px;
      gap: 30px;
    }

    @include mobile-width {
      margin-top: 136px;
      gap: 30px;
    }
  }
  &-title {
    max-width: 870px;
    width: 100%;
  }
  &-infoHolder {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include tablet-width {
      flex-direction: column;
    }

    @include mobile-width {
      flex-direction: column;
    }

    &-fields-holder {
      max-width: 696px;
      width: 100%;
      margin-top: 32px;
      @include tablet-width {
        margin-top: 64px;
      }

      @include mobile-width {
        margin-top: 40px;
      }
    }
    &-text {
      font-family: Metropolis;
      color: var(--black, #22242c);
      text-align: justify;
      margin-top: 72px;
      display: flex;
      max-width: 480px;
      width: 100%;
      max-height: fit-content;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;
      @include h5-desktop;
      @include tablet-width {
        @include h5-tablet;
        margin-top: 48px;
      }

      @include mobile-width {
        @include h5-mobile;
        margin-top: 32px;
      }
    }
  }
}

.contactsWrapper {
  // margin-bottom: 194px;
  padding-bottom: 0px;

  &-itemsHolder {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 18px;

    @include tablet-width {
      width: 100%;
    }

    @include mobile-width {
      width: 100%;
    }
  }
  &-socialsHolder {
    height: 40px;
    width: 122px;
    gap: 24px;
    display: flex;
    flex-direction: row;
  }
  &-item {
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: 100%;
  }
  address {
    gap: 40px;
    font-style: normal;
    display: flex;
    flex-direction: column;
  }
  &-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &-flexInfoHolder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-height: 768px;
    height: 100%;
    @include small-desktop {
      justify-content: initial;
      gap: 200px;
    }
    @include tablet-width {
      max-height: 100%;
      flex-direction: column;
    }

    @include mobile-width {
      max-height: 100%;
      flex-direction: column;
    }
  }
  &-contactsHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 480px;
    min-height: 416px;
    height: 100%;
    gap: 64px;
    @include tablet-width {
      max-width: 100%;
      width: 100%;
      align-items: flex-start;
    }

    @include mobile-width {
      max-width: 100%;
      width: 100%;
      align-items: flex-start;
    }
  }
  &-hire {
    max-width: 576px;
    width: 100%;
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 104px;

    @include tablet-width {
      max-width: 100%;
    }

    @include mobile-width {
      max-width: 100%;
    }

    &-form {
      max-width: 576px;
      width: 100%;
      flex: 0 1 508px;
      margin-right: 10px;
      height: fit-content;

      @include just-small-desktop {
        flex: 0 1 416px;
        max-width: 100%;
      }

      @include tablet-width {
        flex-basis: auto;
        max-width: 100%;
      }
    }
  }

  &-title.title {
    @include tablet-width {
      max-width: 550px;
    }

    @media screen and (max-width: 560px) {
      font-size: 62px;
    }

    @include mobile-width {
      @include h1-mobile;
    }
  }

  &-locations {
    margin-top: 208px;
    margin-bottom: 304px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include small-desktop {
      margin-top: 63px;
    }

    @include tablet-width {
      margin-bottom: 184px;
      margin-top: 56px;
      flex-direction: column;
    }

    @include mobile-width {
      margin-bottom: 136px;
      margin-top: 50px;
    }
  }

  &-location {
    @include h5-desktop;
    width: 576px;
    @include just-small-desktop {
      @include h5-desktop-small;
      flex-basis: 416px;
    }

    @include tablet-width {
      @include h5-tablet;
      min-width: 100%;
      width: 100%;

      &:first-of-type {
        margin-bottom: 48px;
      }
    }

    @include mobile-width {
      width: 100%;
      @include h5-mobile;
      &:first-of-type {
        margin-bottom: 44px;
      }
    }
  }

  &-address {
    justify-content: flex-start;
    margin-bottom: 16px;

    & > p {
      @include h4-desktop;
      margin-right: 16px;

      @include just-small-desktop {
        @include h4-desktop-small;
      }

      @include tablet-width {
        @include h4-tablet;
      }

      @include mobile-width {
        @include h4-mobile;
      }
    }
  }

  &-email {
    display: inline-block;
  }

  &-map {
    width: 100%;
    height: 662px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &__ukr {
      background-image: url('../../assets/locationUkr.jpg');
    }

    &__usa {
      background-image: url('../../assets/locationUSA.jpg');
    }

    @include small-desktop {
      // margin-top: 24px;
    }

    @include tablet-width {
      height: 500px;
      // margin-top: 32px;
    }

    @include mobile-width {
      height: 420px;
      // margin-top: 24px;
    }

    &__pin {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.7);
      -webkit-animation: pin-scale 0.8s linear;
      animation: pin-scale 0.8s linear;

      &:hover {
        -webkit-animation: pin-scale-back 0.4s linear forwards;
        animation: pin-scale-back 0.4s linear forwards;
      }
    }
  }
}

@keyframes pin-scale {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  20%,
  50%,
  80%,
  100% {
    transform: translate(-50%, -50%) scale(0.7);
  }
  40% {
    transform: translate(-50%, -50%) scale(0.9);
  }
  60% {
    transform: translate(-50%, -50%) scale(0.8);
  }
}

@keyframes pin-scale-back {
  0% {
    transform: translate(-50%, -50%) scale(0.7);
  }
  10% {
    transform: translate(-50%, -90%) scale(1.1);
  }
  40%,
  100% {
    transform: translate(-50%, -85%) scale(1);
  }
  60% {
    transform: translate(-50%, -75%) scale(0.9);
  }
}
