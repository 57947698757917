@import '../../styles/main';
.categoryWrapper {
  width: fit-content;
  height: 100%;
  background: var(--grey-03, #f2f3f5);
  display: flex;
  padding: 8px 16px;
  align-items: center;
  line-height: 24px;
  gap: 10px;
  border: 0;
  &:hover {
    color: $grey;
    cursor: pointer;
  }

  &-title {
    @include h6-desktop;
    text-transform: capitalize;
    font-family: Metropolis;
    text-align: -webkit-match-parent;
    font-weight: 400;
    @include small-desktop {
      @include h6-desktop-small;
    }
    @include tablet-width {
      @include h6-tablet;
    }
    @include mobile-width {
      @include h6-mobile;
    }
  }
}
