@import '../../../styles/main';
.smallitemWrapper {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  @include tablet-width {
    display: flex;
    width: 240px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  @include mobile-width {
    display: flex;
    align-items: flex-start;
    width: fit-content;
    gap: 10px;
    flex-direction: initial;
    align-self: stretch;
  }

  &-title {
    &:hover {
      color: $grey;
      cursor: pointer;
    }
    // max-height: 3em;
    // overflow: hidden;
    // text-overflow: ellipsis;
    @include h6-desktop;
    font-family: Metropolis;
    text-transform: capitalize;
    line-height: 24px;
    @include small-desktop {
      @include h6-desktop-small;
    }
    @include tablet-width {
      @include h6-tablet;
    }
    @include mobile-width {
      @include h6-mobile;
      text-transform: initial;
      line-height: 24px;
    }
  }
  &-content {
    @include h5-desktop;
    font-family: Metropolis;
    font-weight: 600;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;

    @include mobile-width {
      display: flex;
      width: 187px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      flex-shrink: 0;
      align-self: stretch;
    }
  }
  &-image {
    width: 140px;
    height: 82px;
    align-self: stretch;
    background-position: center;
    background-size: cover;
    object-fit: cover;
    @include tablet-width {
      max-height: 213px;
      height: 100%;
      width: 100%;
    }
    @include mobile-width {
      width: 82px;
      height: 82px;
    }
  }
  &-date {
    color: rgba(151, 156, 175, 1);
    font-family: Metropolis;
    font-weight: 400;
    @include h5-desktop-small;
    text-align: justify;

    @include small-desktop {
      @include h6-desktop-small;
    }
    @include tablet-width {
      @include h6-tablet;
    }
    @include mobile-width {
      @include h6-mobile;
      text-transform: initial;
      line-height: 24px;
    }
  }
}
