@import '../../styles/devices.mixins';
@import '../../styles/text.mixins';

.genesys {
  &-cooperation {
    padding-top: 150px;

    &-content {
      margin-bottom: 135px;

      @include tablet-width {
        margin-bottom: 28px;
      }

      @include mobile-width {
        margin-bottom: 65px;
      }
    }
  }
  &-support {
    @include big-desktop {
      padding-top: 128px;
    }
  }
  .img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .genesys-logo.img {
    width: 198px;
    height: 40px;
    background-image: url('../../assets/genesys/genesys-logo.jpg');

    @include small-desktop {
      width: 148px;
      height: 30px;
    }

    @include tablet-width {
      width: 277px;
      height: 56px;
    }

    @include mobile-width {
      width: 152px;
      height: 31px;
    }
  }

  &-support-inner {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
  }

  &-support-content {
    flex: 0 1 930px;

    @include just-small-desktop {
      flex-basis: 698px;
    }
  }

  &-support-title {
    max-width: 860px;
    margin: 48px 0 100px;

    @include small-desktop {
      margin: 36px 0 75px;
    }

    @include tablet-width {
      margin: 56px 0 228px;
    }

    @include smallTablet-width {
      margin: 48px 0 100px;
    }

    @include mobile-width {
      margin: 32px 0 48px;
    }
  }

  &-support-text {
    @include h4-desktop;
    text-align: justify;

    @include small-desktop {
      @include h4-desktop-small;
    }

    @include tablet-width {
      @include h4-tablet;
    }

    @include mobile-width {
      @include h4-mobile;
    }
  }

  &-support-logo.img {
    flex: 0 0 165px;
    height: 270px;
    background-image: url('../../assets/genesys/genesys-logo-big.jpg');
    background-size: contain;
    margin-left: 15px;

    @include small-desktop {
      width: 123.75px;
      height: 202.5px;
    }

    @include tablet-width {
      position: absolute;
      width: 198px;
      height: 302px;
      top: 35%;
      right: 0;
      transform: translateY(-50%);
      z-index: -1;
    }

    @include smallTablet-width {
      display: none;
    }
  }

  &-solution {
    @include regular-desktop {
      padding-bottom: 200px;
    }
  }

  &-solution-col {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include tablet-width {
      flex-direction: column;
    }
  }

  &-solution-col:first-of-type {
    margin-bottom: 64px;

    @include small-desktop {
      margin-bottom: 48px;
    }

    @include tablet-width {
      margin-bottom: 0;
    }
  }

  &-solution-title {
    flex: 0 1 650px;

    @include small-desktop {
      flex-basis: 416px;
    }

    @include tablet-width {
      flex: 0 0 0;
      margin-bottom: 16px;
    }

    @include mobile-width {
      margin-bottom: -10px;
    }
  }

  &-solution-item {
    flex: 0 1 576px;

    @include small-desktop {
      flex-basis: 416px;
    }

    @include tablet-width {
      flex: 0 0 0;
      margin-top: 56px;
    }
  }

  &-solution-item-title {
    @include h3-desktop;
    margin: 16px 0 24px;
    text-transform: uppercase;

    @include just-small-desktop {
      @include h3-desktop-small;
      margin: 8px 0 16px;
    }

    @include tablet-width {
      @include h3-tablet;
    }

    @include mobile-width {
      @include h3-mobile;
    }
  }

  &-solution-item-text {
    text-align: justify;
  }

  //services block styling
  &-services {
    display: flex;
    justify-content: space-between;

    @include tablet-width {
      flex-direction: column;
    }
    @include regular-desktop {
      margin-top: 200px;
    }
  }

  &-services-col {
    flex: 0 1;
  }

  &-services-col {
    flex: 0 1 576px;

    @include small-desktop {
      flex-basis: 416px;
    }

    @include tablet-width {
      flex-basis: auto;
    }
  }

  &-services-col:first-of-type {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-services-context {
    @include h4-desktop;
    max-width: 540px;
    text-align: justify;

    @include small-desktop {
      @include h4-desktop-small;
    }

    @include tablet-width {
      @include h4-tablet;
      flex-basis: 0;
      min-width: 100%;
      margin: 72px 0 56px;
    }

    @include mobile-width {
      @include h4-mobile;
      margin: 48px 0 40px;
    }
  }

  &-services-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &:not(:last-of-type) {
      margin-bottom: 40px;
    }

    @include tablet-width {
      max-width: 720px;

      &:not(:last-of-type) {
        margin-bottom: 48px;
      }
    }

    @include mobile-width {
      &:not(:last-of-type) {
        margin-bottom: 32px;
      }
    }
  }

  &-services-item-content {
    margin-left: 32px;

    @include just-small-desktop {
      margin-left: 24px;
    }

    @include mobile-width {
      margin-left: 24px;
    }

    & > h3 {
      @include h3-desktop;
      margin-bottom: 16px;

      @include small-desktop {
        @include h3-desktop-small;
        margin-bottom: 12px;
      }

      @include tablet-width {
        @include h3-tablet;
        margin-bottom: 8px;
      }

      @include mobile-width {
        @include h3-mobile;
      }
    }

    & > p {
      text-align: justify;
    }
  }

  //result section
  &-result-title {
    max-width: 900px;

    @include just-small-desktop {
      max-width: 600px;
    }
  }

  &-result-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include tablet-width {
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
    }
  }

  &-result-text {
    text-align: justify;
    flex: 0 1 480px;

    @include tablet-width {
      flex: 0 0 0;
      margin: 72px 0 64px;
    }

    @include mobile-width {
      margin: 48px 0 40px;
    }
  }

  &-result-list {
    flex: 0 1 576px;

    @include small-desktop {
      flex: 0 1 416px;
    }

    @include tablet-width {
      flex-basis: auto;
    }
  }

  &-result-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &-result-item:not(:last-of-type) {
    margin-bottom: 66px;

    @include small-desktop {
      margin-bottom: 42px;
    }
  }

  &-result-amount {
    @include h2-desktop;
    flex: 0 0 132px;
    margin-right: 32px;

    @include small-desktop {
      @include h2-desktop-small;
      flex-basis: 105px;
    }

    @include tablet-width {
      @include h2-tablet;
      flex-basis: 180px;
    }

    @include mobile-width {
      @include h2-mobile;
      flex-basis: 112px;
    }
  }

  &-result-description {
    @include h4-desktop;

    @include small-desktop {
      @include h4-desktop-small;
    }

    @include tablet-width {
      @include h4-tablet;
    }

    @include mobile-width {
      @include h4-mobile;
    }
  }

  //home-cooperation block
  &-cooperation-title {
    max-width: 1021px;
  }

  &-cooperation-content {
    display: flex;
    justify-content: space-between;
    margin-top: 64px;

    @include small-desktop {
      margin-top: 49px;
    }

    @include tablet-width {
      flex-direction: column;
      max-width: 696px;
      margin-top: 48px;
    }

    @include mobile-width {
      margin-top: 32px;
    }
  }

  &-cooperation-text {
    max-width: 562px;
    text-align: justify;

    @include small-desktop {
      max-width: 440px;
    }

    @include tablet-width {
      margin: 0 0 64px;
      max-width: none;
    }

    @include mobile-width {
      margin: 0 0 40px;
    }
  }

  &-cooperation-form {
    flex-basis: 576px;

    @include small-desktop {
      flex-basis: 416px;
    }
  }
}
