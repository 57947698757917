@import '../../../styles/main';
.link {
  color: blue;
}
.link:hover {
  color: darkblue;
}
.wrapper {
  &-flex-container {
    max-width: 936px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
    @include tablet-width {
      display: flex;
      justify-content: stretch;
      flex-direction: column;
    }

    @include mobile-width {
      display: flex;
      justify-content: stretch;
      flex-direction: column;
    }
  }
  &-contacts-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
    margin-bottom: 24px;
    &-items {
      display: flex;
      justify-content: flex-end;
      height: 24px;
      width: 100%;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    &-text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;
      align-self: stretch;
      &-text {
        @include h5-desktop;
        color: #22242c;
        text-align: justify;
        font-family: Metropolis;
        font-weight: 400;
        @include small-desktop {
          @include h5-desktop-small;
        }
        @include tablet-width {
          @include h6-tablet;
          line-height: 24px;
        }

        @include mobile-width {
          @include h6-mobile;
          line-height: 20px;
        }
        // @include mobile-width {
        //   font-size: 16px;
        //   line-height: 24px;
        // }
      }
    }
    &-subtitle {
      @include h3-desktop;
      color: #22242c;
      text-align: justify;
      font-family: Metropolis;
      font-weight: 500;
      @include small-desktop {
        @include h4-desktop-small;
      }
      @include tablet-width {
        @include h5-tablet;
      }

      @include mobile-width {
        @include h5-mobile;
      }
    }
  }
  &-image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }
  &-image {
    max-height: 460px;
    height: 100%;
    align-self: stretch;
    object-fit: cover;
  }
  &-header-wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    &-disabled {
      display: none;
    }

    &-title-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex-shrink: 0;

      &-title {
        @include h2-desktop;
        color: var(--black, #22242c);
        font-family: Metropolis;
        font-weight: 500;
        text-transform: uppercase;
        @include small-desktop {
          @include h3-desktop;
        }
        @include tablet-width {
          @include h3-tablet;
        }
        @include mobile-width {
          @include h3-mobile;
        }
      }
    }
  }
  &-date {
    color: rgba(151, 156, 175, 1);
    font-family: Metropolis;
    font-weight: 400;
    @include h5-desktop-small;
    @include regular-desktop {
      display: flex;
    }
    @include small-desktop {
      display: flex;
    }
    @include tablet-width {
      @include h6-desktop-small;
      display: flex;
    }
    @include mobile-width {
      display: none;
    }
    &-category {
      display: flex;
      color: rgba(151, 156, 175, 1);
      @include h5-desktop-small;
      font-weight: 400;
      margin-left: 10px;
      @include tablet-width {
        @include h6-desktop-small;
        display: flex;
      }

      &:hover {
        color: #c5c8d3;
        cursor: pointer;
      }
    }
  }
}
.sharePopUp-itemHolder:focus {
  outline: none;
}
.sharePopUp {
  user-select: none;
  display: flex;
  background-color: white;
  width: fit-content;
  height: fit-content;
  justify-content: center;
  flex-direction: column;

  a {
    user-select: none;
  }
  border-radius: 2px;
  border: 1px solid #f2f3f5;
  box-shadow: 0px 0px 3px 0px rgba(193, 193, 193, 0.25);
  align-items: flex-start;
  &-disabled {
    display: none;
  }

  &-itemHolder {
    border: none;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 7px;
    user-select: none;
  }

  &-itemHolder:first-child {
    display: flex;
    padding-bottom: 14px;
    padding-top: 14px;
    flex-direction: row;
    border-radius: 2px 2px 0px 0px;
    border-bottom: 0.5px solid #c5c8d3;
    background: #fff;
  }

  &-itemsHolder {
    user-select: none;
    display: flex;
    flex-direction: column;
    :hover {
      background-color: #f2f3f5;
      cursor: pointer;
    }
  }

  &-icon {
    width: 18px;
    height: 18px;
  }

  p {
    font-family: Metropolis;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.shareButton {
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-image: url('../../../assets/svgIcons/ShareNetwork.svg');
}
