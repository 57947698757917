@import '../../styles/main';

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: $white;
  z-index: 9;
  // overflow-y: scroll;

  &-inner {
    @include flex;
    max-width: 1570px;
    padding: 8px 77px 14px;
    margin: 0 auto;

    @include small-desktop {
      max-width: 1150px;
      padding: 17px 50px 29px;
    }

    @include tablet-width {
      padding: 24px 44px;
    }

    @include mobile-width {
      height: 80px;
      padding: 16px 16px;
    }
  }

  &-inner-contacts {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 1570px;
    padding: 14px 77px 8px;
    margin: 0 auto;

    @include small-desktop {
      max-width: 1150px;
      padding: 24px 50px 0px;
    }

    @include tablet-width {
      display: none;
      padding: 0px 44px;
    }

    @include mobile-width {
      display: none;
      padding: 0px 16px;
    }
  }
}

.sideBarItem {
  display: none;
  @include tablet-width {
    display: inline-flex;
  }

  @include mobile-width {
    display: inline-flex;
  }

  &.phoneWrapper {
    @include tablet-width {
      padding: 38px 0 8px 0;
    }

    @include mobile-width {
      padding: 24px 0 9px 0;
    }
  }

  &.socialWrapper {
    padding-top: 8px;
    justify-content: space-between;
    order: 5;
    @include tablet-width {
      width: 208px;
    }

    @include mobile-width {
      width: 144px;
    }
  }
}

.hire-btn {
  display: none;
  position: relative;
  z-index: 999;
  width: 300px;
  height: 48px;
  flex-grow: 1;

  &.offButton {
    @include tablet-width {
      display: none;
    }

    @include mobile-width {
      display: none;
    }
  }

  @include tablet-width {
    display: inline-flex;
    height: 46px;
    min-height: 46px;
    justify-content: flex-end;
    align-items: center;
    margin: 0 5px 0 5px;
  }

  @include mobile-width {
    max-width: 128px;
    max-height: 46px;
    min-width: 100px;
    min-height: 46px;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 5px 0 5px;
  }
}

.nav {
  flex-basis: 739px;
  display: inline-flex;
  align-items: center;

  @include small-desktop {
    flex-basis: 500px;
  }

  @include tablet-width {
    flex-basis: 100px;
  }

  @include mobile-width {
    flex-basis: 10%;
  }

  // &-buttonHolder {
  //   max-width: 242px;
  //   max-height: 64px;
  //   width: 100%;
  //   height: 100%;
  // }

  .toggle-btn {
    display: none;
    position: relative;
    z-index: 999;
    width: 64px;
    height: 32px;
    margin-left: auto;

    @include tablet-width {
      display: inline-flex;
      justify-content: flex-end;
    }

    @include mobile-width {
      width: 40px;
      height: 20px;
    }

    & > .toggle-bar {
      position: absolute;
      left: 0;
      background: $black;
      height: 3px;
      width: 100%;
      transition: opacity ease-in-out 0.6s;
      z-index: 999;

      @include mobile-width {
        height: 2px;
      }

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        width: 48px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include mobile-width {
          width: 30px;
        }
      }

      &:nth-child(3) {
        bottom: 0;
      }
    }
  }

  .menu {
    @include flex;
    width: 100%;
    gap: 14px;
    @include tablet-width {
      height: 100%;
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 99;
      overflow-y: auto;
      transform: translateX(100%);
      transition: all ease-in-out 0.6s;
      flex-direction: column;
      justify-content: center;
      background-color: $white;
    }
  }

  &.toggled {
    .menu {
      transform: translateX(0%);
    }

    .toggle-btn > .toggle-bar {
      background: $grey;

      &:nth-child(1) {
        -webkit-animation: bar-sliding-down ease-in-out 600ms forwards normal;
        animation: bar-sliding-down ease-in-out 600ms forwards normal;
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        -webkit-animation: bar-sliding-up ease-in-out 600ms forwards normal;
        animation: bar-sliding-up ease-in-out 600ms forwards normal;
      }
    }
  }

  &.toggled-off {
    .toggle-btn > .toggle-bar {
      &:nth-child(1) {
        -webkit-animation: bar-sliding-down-reverse ease-in-out 600ms forwards reverse;
        animation: bar-sliding-down-reverse ease-in-out 600ms forwards reverse;
      }
      &:nth-child(3) {
        -webkit-animation: bar-sliding-up-reverse ease-in-out 600ms forwards reverse;
        animation: bar-sliding-up-reverse ease-in-out 600ms forwards reverse;
      }
    }
  }
}

.contacts {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 16px;
  @include tablet-width {
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 99;
    overflow-y: auto;
    transform: translateX(100%);
    transition: all ease-in-out 0.6s;
    flex-direction: column;
    justify-content: center;
    background-color: $white;
  }
  &-wrapper1 {
    display: flex;
    width: fit-content;
    gap: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-wrapper2 {
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
}

.logo {
  z-index: 999;
  cursor: pointer;

  &-enable {
    @include flex;
    & > img:first-of-type {
      height: 100%;
      width: auto;
    }

    & > img:last-of-type {
      width: 100%;
      height: auto;
    }
    @include tablet-width {
      width: 216px;
      height: 64px;

      display: inline-flex;
      align-items: center;

      & > img:first-of-type {
        height: 100%;
        width: auto;
      }

      & > img:last-of-type {
        width: 100%;
        height: auto;
      }
    }

    @include mobile-width {
      width: 133px;
      height: 80px;
      & > img:first-of-type {
        width: 40px;
        height: 40px;
      }
      & > img:last-of-type {
        width: 88px;
        height: 22px;
      }
    }
  }
  &-link {
    @include flex;
    width: 169px;
    height: 50px;

    @include tablet-width {
      width: 216px;
      height: 64px;
    }

    @include mobile-width {
      width: 40px;
      height: 40px;
      & > img:last-of-type {
        display: none;
      }
    }

    & > img:first-of-type {
      height: 100%;
      width: auto;
      @include mobile-width {
        height: 40px;
        width: 40px;
      }
    }

    & > img:last-of-type {
      width: 100%;
      height: auto;
    }
  }
}

@keyframes bar-sliding-down {
  0% {
    top: 0;
  }
  50% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  100% {
    top: 50%;
    left: 50%;
    transform: rotate(45deg) translate(-25px, 22px);
  }
}

@keyframes bar-sliding-down-reverse {
  0% {
    top: 0;
  }
  50% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  100% {
    top: 50%;
    left: 50%;
    transform: rotate(45deg) translate(-25px, 22px);
  }
}

@keyframes bar-sliding-up {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  100% {
    top: 50%;
    left: 50%;
    transform: rotate(-45deg) translate(-22px, -25px);
  }
}

@keyframes bar-sliding-up-reverse {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  100% {
    top: 50%;
    left: 50%;
    transform: rotate(-45deg) translate(-22px, -25px);
  }
}
