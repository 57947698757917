@import '../../../styles/main';
@import '../../../styles/color.scss';
.itemWrapper {
  display: flex;
  width: 456px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  margin-bottom: 24px;
  @include small-desktop {
    width: 100%;
  }

  @include tablet-width {
    width: 100%;
  }

  @include mobile-width {
    width: 100%;
  }

  &-text-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
  }

  &-title {
    display: flex;
    max-height: 3em;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    @include h4-desktop;
    overflow: hidden;
    font-family: Metropolis;
    text-transform: uppercase;
    @include tablet-width {
      @include h3-tablet;
    }

    @include mobile-width {
      @include h3-mobile;
    }

    &-holder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      &:hover a {
        color: $grey;
        cursor: pointer;
      }
    }
  }
  &-content {
    @include h5-desktop;
    font-family: Metropolis;
    color: var(--black, #22242c);
    text-align: justify;
    font-weight: 400;
    @include tablet-width {
      @include h5-tablet;
      color: var(--black, #22242c);
      text-align: justify;
      font-weight: 400;
      font-family: Metropolis;
    }

    @include mobile-width {
      @include h5-mobile;
      color: var(--black, #22242c);
      text-align: justify;
      font-weight: 400;
      font-family: Metropolis;
    }
  }
  &-image {
    height: 260px;
    align-self: stretch;
    object-fit: contain;
    @include small-desktop {
      height: 336px;
    }
    @include tablet-width {
      height: 336px;
    }
    @include mobile-width {
      height: 230px;
      width: 100%;
    }
  }
}

.info-wrapper {
  display: flex;
  width: 100%;
  max-height: 116px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  &-bar {
    display: flex;
    align-items: center;
    gap: 16px;

    &-category {
      display: flex;
      flex-direction: column;
      width: fit-content;
      height: 100%;
      gap: 10px;
    }
    &-date {
      color: #22242c;
      font-family: Metropolis;
      font-weight: 400;
      font-size: 16px;
      cursor: pointer;
      @include regular-desktop {
        display: flex;
      }
      @include small-desktop {
        display: flex;
      }
      @include tablet-width {
        display: flex;
      }
      @include mobile-width {
        display: flex;
      }
    }
    &-date:hover {
      color: #979caf;
    }
    &-mobile-date {
      color: #22242c;
      font-family: Metropolis;
      font-weight: 400;
      @include h5-desktop-small;
      @include regular-desktop {
        display: none;
      }
      @include small-desktop {
        display: none;
      }
      @include tablet-width {
        display: none;
      }
      @include mobile-width {
        display: flex;
      }
    }
  }
  &-button {
    width: 24px;
    height: 24px;
    @include regular-desktop {
      display: flex;
    }
    @include small-desktop {
      display: flex;
    }
    @include tablet-width {
      display: none;
    }
    @include mobile-width {
      display: none;
    }
  }
  &-contacts {
    height: 32px;
    display: flex;
    align-items: center;
    @include regular-desktop {
      display: none;
    }
    @include small-desktop {
      display: none;
    }
    @include tablet-width {
      display: flex;
      height: 32px;
      gap: 24px;
    }
    @include mobile-width {
      display: flex;
      height: 24px;
      gap: 16px;
    }
  }
}
