@import '../../styles/color';
@import '../../styles/devices.mixins';
@import '../../styles/text.mixins';

.services {
  &-subwrapper {
    @include regular-desktop {
      display: flex;
      justify-content: space-around;
      height: calc(100vh - 260px);
      flex-direction: column;
    }
  }
  &-subwrapper:last-of-type {
    @include regular-desktop {
      display: flex;
      justify-content: space-around;
      height: calc(100vh - 260px);
      flex-direction: column;
    }
    & .services-item {
      margin-bottom: 0px;
    }
    &:last-of-type {
      margin-bottom: 200px;
      @include tablet-width {
        margin-bottom: 100px;
      }
      @include mobile-width {
        margin-bottom: 100px;
      }
    }
  }

  &-item {
    display: flex;
    justify-content: space-between;
    @include regular-desktop {
      margin-bottom: 0px;
    }
    @include tablet-width {
      flex-direction: column;
      margin-bottom: 144px;
    }
    @include mobile-width {
      margin-bottom: 96px;
    }
  }

  &-item:first-of-type &-title {
    max-width: none;
  }

  &-content {
    flex: 0 1 696px;
    margin-right: 15px;

    @include small-desktop {
      flex-basis: 480px;
    }

    @include tablet-width {
      flex-basis: 0;
    }
  }

  &-text {
    @include h5-desktop;
    margin-top: 32px;
    text-align: justify;

    @include small-desktop {
      @include h5-desktop-small;
      margin-top: 24px;
    }

    @include tablet-width {
      @include h5-tablet;
      margin-top: 72px;
    }

    @include mobile-width {
      @include h5-mobile;
      margin-top: 48px;
    }
  }

  &-list {
    @include h4-desktop;
    flex: 0 1 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 96px;
    grid-row-gap: 32px;
    align-self: flex-end;

    @include small-desktop {
      @include h4-desktop-small;
      flex-basis: 416px;
      grid-column-gap: 80px;
      grid-row-gap: 30px;
    }

    @include tablet-width {
      @include h4-tablet;
      flex-basis: 0;
      margin-top: 48px;
      grid-row-gap: 40px;
      align-self: stretch;
    }

    @include smallTablet-width {
      margin-top: 40px;
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 32px;
    }

    @include mobile-width {
      @include h4-mobile;
    }
  }

  &-list-item {
    display: flex;
    align-items: center;
    max-width: 240px;

    @include tablet-width {
      max-width: none;
    }
  }

  &-list-item-img {
    flex: 0 0 auto;
    margin-right: 34px;

    @include small-desktop {
      margin-right: 24px;
    }
  }

  &-title {
    max-width: 390px;

    & .title {
      @include h2-desktop;
    }

    @include small-desktop {
      max-width: 370px;
      & .title {
        @include h2-desktop-small;
      }
    }

    @include tablet-width {
      max-width: none;

      & .title {
        @include h2-tablet;
      }
    }

    @include mobile-width {
      & .title {
        @include h2-mobile;
      }
    }
  }
}
