@import '../../../styles/devices.mixins';

.title {
  font-weight: 800;
  font-size: 80px;
  line-height: 80px;
  text-transform: uppercase;
  font-family: Metropolis;
}

.titleHolder {
  max-width: 734px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 44px;
}

.itemsContainer {
  display: inline-flex;
  gap: 24px;

  @include tablet-width {
    flex-direction: column;
    width: 80%;
    align-self: center;
  }
}
.subtitle {
  font-family: Metropolis;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: justify;
}
