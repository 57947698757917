@import '../../styles/devices.mixins';
@import '../../styles/color';
@import '../../styles/text.mixins';

.menu-item {
  @include h6-desktop;
  cursor: pointer;
  position: relative;
  text-wrap: nowrap;
  white-space: nowrap;
  @include small-desktop {
    @include h6-desktop-small;
  }

  @include tablet-width {
    @include h2-desktop;
    margin: 8px 0;
    order: 5;
  }

  @include mobile-width {
    @include h3-desktop;
    margin: 0px 0;
    order: 5;
  }

  .nav-item-label {
    position: absolute;
    top: 60px;
    font-size: 10px;
    line-height: 10px;
    font-weight: 700;

    &.title-overlay--blue {
      background: radial-gradient(
        circle,
        #53dbf9 0%,
        #0846e6 20%,
        #213daf 30%,
        #091f77 40%,
        #22242c 80%,
        #22242c 100%
      );

      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
.nav-title {
  position: relative;
}

.nav-title:hover {
  color: $grey;
}

.nav-title .labelOnTop:hover {
  color: inherit;
}
.labelOnTop {
  position: absolute;
  margin-left: 1px;
  top: -200%;
  line-height: 10px !important;

  @include small-desktop {
    margin-right: 1px;
    top: -200%;
    bottom: 0;
  }
  @include tablet-width {
    margin-left: 3px;
    top: -65%;
    bottom: 0;
  }

  @include mobile-width {
    margin-left: 3px;
    top: -30%;
    bottom: 0;
  }

  span {
    font-size: 10px;
    line-height: 10px;

    @include small-desktop {
      font-size: 8px;
      line-height: 8px;
    }

    @include tablet-width {
      font-size: 24px;
      line-height: 24px;
    }

    @include mobile-width {
      font-size: 16px;
      line-height: 16px;
    }
  }
}
