@import "../../styles/color";
@import "../../styles/text.mixins";

.page404 {
  z-index: 999999;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $black;
  color: $grey-2;
  overflow: hidden;

  &-content {
    overflow: hidden;
    z-index: 999999;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $black;
    color: $grey-2;

  }

  &-message {
    @include h4-desktop;
    max-width: 569px;
    margin: 56px auto 93px;
    text-align: center;

    @include just-small-desktop {
      @include h4-desktop-small;
      max-width: 427px;
      margin: 42px auto 71px;
    }

    @include tablet-width {
      @include h4-tablet;
      margin: 128px auto 102px;
    }

    @include smallTablet-width {
      max-width: 80%;
    }

    @include mobile-width {
      @include h4-mobile;
      margin: 44px auto 80px;
    }
  }

  &-menu {
    @include h6-desktop;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    gap: 100px;

    @include small-desktop {
      @include h6-desktop-small;
      width: 456px;
      margin: 0 auto;
      gap: 50px;
    }


    @include tablet-width {
      width: 600px;
      @include h6-desktop;
      gap: 10px;
    }

    @include smallTablet-width {
      width: 80%;
      gap: 10px;
    }

    @include mobile-width {
      flex-direction: column;
      text-align: center;
      gap: 5px;

      .page404-menu-item:not(:last-of-type) {
        margin-bottom: 32px;
      }
    }
  }

  &-title {
    font-weight: 800;
    font-size: 376px;
    line-height: 376px;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
    text-align: center;

    @include smallTablet-width {
      font-size: 218px;
      line-height: 218px;
    }

    @media screen and (max-width: 550px) {
      font-size: 198px;
      line-height: 198px;
    }

    @include mobile-width {
      font-size: 156px;
      line-height: 156px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 300%;
      height: 300%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: radial-gradient(circle at center, rgba(34, 36, 44, 0) 0%, rgba(34, 36, 44, 0.3) 3%, rgba(34, 36, 44, 0.8) 10%, #22242C 20%, #22242C 100%);
      filter: blur(4px);
      -webkit-animation: colorMoving-404 linear 8s infinite;
      animation: colorMoving-404 linear 8s infinite;
      z-index: 10;
    }

  }
}

@keyframes colorMoving-404 {

  0%,
  5% {
    top: 80%;
    left: 50%;
  }

  14%,
  19% {
    top: 80%;
    left: 30%;
  }

  31%,
  36% {
    top: 30%;
    left: 80%;
  }

  48%,
  52% {
    top: 90%;
    left: 90%;
  }

  64.5%,
  70% {
    top: 20%;
    left: 20%;
  }

  79%,
  85% {
    top: 40%;
    left: 30%;
  }

  100% {
    top: 80%;
    left: 50%;
  }
}