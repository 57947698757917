@import '../../styles/devices.mixins';
@import '../../styles/text.mixins';
@import '../../styles/color';

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.dotsTitle {
  font-weight: 600;
}
.contentTitle {
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  text-align: left;
}

.privacyPolicyLink {
  font-weight: 600;
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 54px;
  padding-bottom: 150px;

  @include small-desktop {
    padding-top: 147px;
  }
}
.subWrapper {
  display: inline-flex;
  width: 100%;
  height: 100%;
  gap: 144px;
  @include tablet-width {
    display: flex;
    flex-direction: column-reverse;
    gap: 60px;
  }
}

.sideBar {
  position: sticky;
  top: 121px;
  display: flex;
  flex-direction: column;
  min-width: 336px;
  gap: 32px;
  height: fit-content;
  padding: 13px 0px 0px 0px;
  @include small-desktop {
    top: 147px;
  }
  @include tablet-width {
    position: initial;
    top: 120px;
  }
}

.termListItem {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.textWrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.lastUpdateLabel {
  color: #666666;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.subItemsHolder {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.text {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
}
.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.mainTitle {
  font-size: 60px;
  font-weight: 700;
  line-height: 73.26px;
  text-align: left;
}
.icon {
  width: 27px;
  height: 27px;
}
.welcomeTitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
}

.subTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
}

.textContent {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
}

.welcomeWrapper {
  display: inline-flex;

  gap: 10px;
}

.title {
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
}
.subItemsWrapper {
  display: flex;
  flex-direction: column;
  list-style-type: disc;
  gap: 14px;
}
.liContainer {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.strongText {
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  text-align: left;
}

.policyUL {
  list-style-type: disc;
  padding-left: 24px;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 14px;
  li {
    list-style-type: disc;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
  }
}

.mainUL {
  list-style-type: disc;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 14px;
  li {
    list-style-type: disc;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
  }
}
