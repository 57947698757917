@import '../../styles/color';
@import '../../styles/general.styles';

.title-overlay,
.title-overlay-partly {
  position: relative;
  overflow: hidden;
  isolation: isolate;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    display: block;
    animation: colorMoving linear 15s infinite;
    z-index: -1;
    filter: blur(10px);
    -webkit-transform: translate3d(0, 0, 0);
  }

  &.title-overlay--blue::before {
    background: radial-gradient(
      circle,
      #53dbf9 0%,
      #0846e6 20%,
      #213daf 30%,
      #091f77 40%,
      #22242c 80%,
      #22242c 100%
    );
  }

  &.title-overlay--customBlue::before {
    background: radial-gradient(
      circle,
      #53dbf9 100%,
      #53dbf9 80%,
      #0846e6 30%,
      #213daf 20%,
      #091f77 10%,
      #091f77 0%
    );
  }

  &.title-overlay--dark-blue::before {
    background: radial-gradient(
      circle,
      #7047ed 0%,
      #0009e5 20%,
      #311fa3 30%,
      #311fa3 40%,
      #1e075f 80%,
      #1e075f 100%
    ); //#7047ed instead of  #B77FFE  at 0% position
  }

  &.title-overlay--purple::before {
    background: radial-gradient(
      circle,
      #b6aaff 0%,
      #7708e6 20%,
      #4f21af 30%,
      #460977 40%,
      #22242c 80%,
      #22242c 100%
    );
  }

  &.title-overlay--green::before {
    background: radial-gradient(
      circle,
      #41e3ed 0%,
      #08d8e6 20%,
      #219eaf 30%,
      #096377 40%,
      #22242c 80%,
      #22242c 100%
    );
  }

  &.title-overlay--red::before {
    background: radial-gradient(
      circle,
      #f9535d 0%,
      #e6083e 20%,
      #af214c 30%,
      #770930 40%,
      #22242c 80%,
      #22242c 100%
    );
  }

  &.title-overlay--orange::before {
    background: radial-gradient(
      circle,
      #fd984f 0%,
      #ee5424 20%,
      #cd3505 30%,
      #5f1903 40%,
      #22242c 80%,
      #22242c 100%
    );
  }

  & > .title,
  & > span {
    mix-blend-mode: screen;
    background-color: $white;
    width: 100%;
    height: 100%;
    word-wrap: break-word;
    white-space: pre;
  }
}

.title-overlay-custom,
.title-overlay-partly-custom {
  position: relative;
  overflow: hidden;
  isolation: isolate;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: block;
    animation: colorMovingCustom linear 5s infinite;
    z-index: -1;
    filter: blur(10px);
    -webkit-transform: translate3d(0, 0, 0);
  }

  &.title-overlay--blue::before {
    width: 150px;
    height: 150px;
    background: radial-gradient(
      circle,
      #53dbf9 0%,
      #53dbf9 50%,
      #213daf 70%,
      #091f77 80%,
      #22242c 95%,
      #22242c 100%
    );
  }

  &.title-overlay--customBlue::before {
    background: radial-gradient(
      circle,
      #53dbf9 100%,
      #53dbf9 80%,
      #0846e6 30%,
      #213daf 20%,
      #091f77 10%,
      #091f77 0%
    );
  }

  &.title-overlay--dark-blue::before {
    background: radial-gradient(circle, #53dbf9 0%, #53dbf9 50%, #213daf 70%, #091f77 100%);
  }

  &.title-overlay--purple::before {
    background: radial-gradient(
      circle,
      #b6aaff 0%,
      #7708e6 20%,
      #4f21af 30%,
      #460977 40%,
      #22242c 80%,
      #22242c 100%
    );
  }

  &.title-overlay--green::before {
    background: radial-gradient(
      circle,
      #41e3ed 0%,
      #08d8e6 20%,
      #219eaf 30%,
      #096377 40%,
      #22242c 80%,
      #22242c 100%
    );
  }

  &.title-overlay--red::before {
    background: radial-gradient(
      circle,
      #f9535d 0%,
      #e6083e 20%,
      #af214c 30%,
      #770930 40%,
      #22242c 80%,
      #22242c 100%
    );
  }

  &.title-overlay--orange::before {
    background: radial-gradient(
      circle,
      #fd984f 0%,
      #ee5424 20%,
      #cd3505 30%,
      #5f1903 40%,
      #22242c 80%,
      #22242c 100%
    );
  }

  & > .title,
  & > span {
    mix-blend-mode: screen;
    background-color: $white;
    width: 100%;
    height: 100%;
    word-wrap: break-word;
    white-space: pre;
  }
}

.title-overlay-partly-custom {
  display: inline-block;
  display: -webkit-inline-box; //for prevent span being a litle bit down in accordanse to text, inside which it is
  -webkit-transform: translate3d(0, 0, 0); //to remove artifacts while moving background
  transform: translate3d(0, 0, 0);

  & > span {
    display: inline-block;
  }
}

.titleFlex {
  display: inline-flex;
}

.title-overlay-partly {
  display: inline-block;
  display: -webkit-inline-box; //for prevent span being a litle bit down in accordanse to text, inside which it is
  -webkit-transform: translate3d(0, 0, 0); //to remove artifacts while moving background
  transform: translate3d(0, 0, 0);

  & > span {
    display: inline-block;
  }
}

@keyframes colorMoving {
  0% {
    top: 50%;
    left: -50%;
  }
  25% {
    top: -50%;
    left: 50%;
  }
  50% {
    top: -50%;
    left: -50%;
  }
  75% {
    top: 50%;
    left: 50%;
  }
  100% {
    top: 50%;
    left: -50%;
  }
}

@keyframes colorMovingCustom {
  0% {
    top: -200%;
    left: -40%;
  }
  25% {
    top: -150%;
    left: -5%;
  }
  50% {
    top: -100%;
    left: -40%;
  }
  75% {
    top: -85%;
    left: -5%;
  }
  100% {
    top: -200%;
    left: -40%;
  }
}
