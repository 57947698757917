@import '../../styles/color';
@import '../../styles/devices.mixins';

* {
  box-sizing: border-box;
}

.video-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    color: white;
  }

  .video {
    width: 100%;
    height: auto;
  }

  .video-wrapper {
    min-width: 100%;
    max-width: 700px;
    max-height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }

  //.video-wrapper:hover .controls, //:hover does not have desirable effect on IOS phone, so click is needed
  .controls.is-shown {
    transform: translateY(0%);
  }

  .video-play-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    transition: all 0.3s ease-in-out;
  }

  .video-play-button {
    position: absolute;
    content: url('../../assets/video/play-state.svg');
    width: 64px;
    height: 64px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;
  }

  .video-play-button.is-hidden {
    opacity: 0;
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 4px 16px;
    flex-wrap: wrap;
    transform: translateY(150%);
    transition: all 0.3s ease-in-out, background-color 0s;
  }

  .actions-btn {
    width: 32px;
    height: 32px;
  }

  .mute-btn {
    width: 24px;
    height: 24px;
  }

  .btn {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    width: 100%;
    height: 100%;

    &.btn-play {
      background-image: url('../../assets/video/play-state.svg');
    }
    &.btn-pause {
      background-image: url('../../assets/video/pause-state.svg');
    }

    &.btn-volume-unmute {
      background-image: url('../../assets/video/unmute.svg');
    }
    &.btn-volume-mute {
      background-image: url('../../assets/video/mute.svg');
    }

    &.btn-full-screen {
      background-image: url('../../assets/video/full-screen.svg');
      width: 16px;
      height: 16px;
    }
  }

  .progressbar {
    position: relative;
    background: $grey;
    height: 5px;
    width: 77%;
    cursor: pointer;

    @include mobile-width {
      width: 65%;
    }
  }

  .progressbar-done {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: $black;

    &:before {
      position: absolute;
      content: '';
      width: 6px;
      height: 6px;
      right: 0;
      top: 50%;
      transform: translate(50%, -50%);
      border-radius: 50%;
      background: inherit;
    }
  }
}

@mixin fullscreen-styling-desktop {
  .video {
    z-index: -1;
    color-scheme: light;
    background-color: $white;
  }

  .btn.btn-full-screen {
    background-image: url('../../assets/video/exit-full-screen.svg');
    width: 25px;
  }
}

@mixin fullscreen-styling-tablet {
  .controls {
    padding: 10px 20px;
    z-index: 9999;
  }

  .progressbar {
    height: 7px;
    width: 90%;

    &-done:before {
      width: 10px;
      height: 10px;
    }
  }
}

@mixin fullscreen-styling-mobile {
  .progressbar {
    width: 50%;
  }
}

.video-container:fullscreen {
  @include fullscreen-styling-desktop;
}
.video-container:-moz-full-screen {
  @include fullscreen-styling-desktop;
}
.video-container:-webkit-full-screen {
  @include fullscreen-styling-desktop;
}
.video-container:-ms-fullscreen {
  @include fullscreen-styling-desktop;
}

//for devices what do not support fullscreen API
.video-container.mocked-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999999999;
  background-color: $black;

  .controls {
    background-color: rgba(255, 255, 255, 0.8);
  }

  @include fullscreen-styling-desktop;
}

@media screen and (min-width: 1220px) {
  .video-container:fullscreen {
    @include fullscreen-styling-tablet;
  }
  .video-container:-moz-full-screen {
    @include fullscreen-styling-tablet;
  }
  .video-container:-webkit-full-screen {
    @include fullscreen-styling-tablet;
  }
  .video-container:-ms-fullscreen {
    @include fullscreen-styling-tablet;
  }
  .video-container.mocked-fullscreen {
    @include fullscreen-styling-tablet;
  }
}

@media screen and (max-width: 420px) {
  .video-container:fullscreen {
    @include fullscreen-styling-mobile;
  }
  .video-container:-moz-full-screen {
    @include fullscreen-styling-mobile;
  }
  .video-container:-webkit-full-screen {
    @include fullscreen-styling-mobile;
  }
  .video-container:-ms-fullscreen {
    @include fullscreen-styling-mobile;
  }
  .video-container.mocked-fullscreen {
    @include fullscreen-styling-mobile;
  }
}
